const resources = document.querySelector('.resources');
if (resources) {
const resourceArr = [...document.getElementsByClassName('resource')];

resourceArr.forEach(resource => {
  resource.addEventListener('click', (e) => {
    resourceCollapse();

    const resourceBody = resource.querySelector('.resource_body');
    const height = getHeight(resourceBody);
    resource.classList.add('open');
    resourceBody.style.height = height;
    resourceBody.style.marginBottom = '1.5rem';
  });
});

function getHeight(resourceBody) {
  resourceBody.style.display = 'flex';
  const height = resourceBody.scrollHeight + 'px';
  resourceBody.style.marginBottom = '1.5rem';
  resourceBody.style.marginBottom = '0';
  resourceBody.style.display = '';
  return height;
};

function resourceCollapse() {
    const openResource = resources.querySelector('.open');
    if (openResource) {
      const openResourceBody = openResource.querySelector('.resource_body');
      openResourceBody.style.height = openResourceBody.offsetHeight + 'px';
      
      setTimeout(() => {
        openResourceBody.style.height = '0';
        openResourceBody.style.marginBottom = '0';
      }, 100);
      
      setTimeout(() => {
        openResource.classList.remove('open');
      }, 350)
    }
  };
}