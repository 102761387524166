const searchbarContainer = document.getElementById('searchbar-container');
const searchbar = document.getElementById('searchbar');
const searchbarInput = document.getElementById('searchbar-input');
const searchResultsMessage = document.getElementById('search-results_message');
const clearBtn = document.getElementById('clear-search');
const filterBtns = [...document.getElementsByClassName('filter-button')];
let teachingsAll = document.getElementById('teachings-all');
let teachingsArr = [];

const teachingMonths = new Array();
teachingMonths[0] = "January";
teachingMonths[1] = "February";
teachingMonths[2] = "March";
teachingMonths[3] = "April";
teachingMonths[4] = "May";
teachingMonths[5] = "June";
teachingMonths[6] = "July";
teachingMonths[7] = "August";
teachingMonths[8] = "September";
teachingMonths[9] = "October";
teachingMonths[10] = "November";
teachingMonths[11] = "December";

// CACHE COLLECTIONS
const cacheCollections = () => {

	let sermons = '/!/Fetch/collection/sermons';
	// let products = '/!/Fetch/collection/products';
	const sermonRequest = axios.get(sermons);
	// const productRequest = axios.get(products);

	axios.all([sermonRequest]).then(axios.spread((...responses) => {
		searchbarInput.removeAttribute('disabled');
		searchbarInput.setAttribute('placeholder', 'Title, Speaker, Series, etc...');
		const sermonRes = responses[0].data.data
		// const productsRes = responses[1].data.data
		console.log('Sermon', sermonRes)
		// console.log('Products', productsRes)
		teachingsArr = [...sermonRes]
	})).catch(errors => {
		console.log({errors});
	});
}

if (window.location.pathname === '/sunday-teachings') {
	cacheCollections();
}

// SORT ARRAY
const sortArr = (arr) => {
	const sortedArr = arr.slice().sort((a,b) => {
		return new Date(b.date_of_teaching) - new Date(a.date_of_teaching)
	})
	return sortedArr
}

// SEARCH FUNCTIONS
const matchTitle = (title, searchTerm) => {
	if ( title.includes(searchTerm) ) {
		return true
	}
}

const matchTerm = (item, searchTerm) => {
	if ( item.hasOwnProperty('sermon_terms') ) {
		const sermonTerms = item.sermon_terms;
		const isMatch = (element) => element.title.toLowerCase().includes(searchTerm.toLowerCase());
		const matchSermonTerm = sermonTerms.some(isMatch);
		return matchSermonTerm
	}
}

const matchSpeaker = (item, searchTerm) => {
	if ( item.hasOwnProperty('sermon_speakers') ) {
		const sermonSpeakers = item.sermon_speakers;
		const isMatch = (element) => element.title.toLowerCase().includes(searchTerm.toLowerCase());
		const matchSermonSpeaker = sermonSpeakers.some(isMatch);
		return matchSermonSpeaker
	}
}

const matchSeries = (item, searchTerm) => {
	if ( item.hasOwnProperty('teachings_series') ) {
		const sermonSeries = item.teachings_series;
		const isMatch = (element) => element.title.toLowerCase().includes(searchTerm.toLowerCase());
		const matchSermonSeries = sermonSeries.some(isMatch);
		return matchSermonSeries
	}
}

// FILTER ITEMS TO RETURN
const filterInput = (searchType) => {
	clearResults();
	searchbarContainer.scrollIntoView(top);
	const searchTerm = searchbarInput.value.toLowerCase();
	console.log({searchTerm})
	const showArr = teachingsArr.filter(item => {
		const title = item.title.toLowerCase();
		if ( searchType == 'series' ) {
			if ( matchSeries(item, searchTerm) ) {
				return item
			}
		// } else if ( searchType == 'products' ) {
		// 	return item.hasOwnProperty('price');
		// } else if ( searchType == 'all' ) {
		// 	if ( ! item.hasOwnProperty('price') ) {
		// 		return item
		// 	}
		} else if (
			matchTitle(title, searchTerm) ||
			matchTerm(item, searchTerm) ||
			matchSpeaker(item, searchTerm) ||
			matchSeries(item, searchTerm)
		) {
			return item
		}
	})
	console.log('Show Array', showArr);
	const newSortedArr = sortArr(showArr)
	console.log({newSortedArr})
	appendCards(newSortedArr);
	newSortedArr.length > 0 ?
		updateSearchMessage(`Results for "${searchTerm}"`, 'block') :
		updateSearchMessage(`No Results for: ${searchTerm}`, 'block')

	clearBtn.style.display = 'block';
}

// CREATE CARDS
const appendCards = (showArr) => {
	console.log({showArr})
	teachingsAll.innerHTML = '';
	showArr.forEach(element => {
		teachingsAll.appendChild(createCard(element))
	});
}

// CARD TEMPLATE
const createCard = (element) => {
	const teaching = document.createElement('li');
				teaching.classList.add('teaching');
	const teachingLink = document.createElement('a');
				teachingLink.setAttribute('href', element.url );
				teachingLink.classList.add('teaching-link');
	const teachingImage = document.createElement('img');
				if ( element.hasOwnProperty('teachings_series')) {
					teachingImage.setAttribute('src', element.teachings_series[0].series_image);
				}
	const teachingIcon = document.createElement('i');
				teachingIcon.setAttribute('class', 'fas fa-play');
	const teachingDate = new Date(element.hasOwnProperty('sermon_date') ? element.sermon_date : element.date_of_teaching)
	const teachingDateHolder = document.createElement('p');
				teachingDateHolder.classList.add('search-results_teaching-date');
				teachingDateHolder.innerHTML = teachingMonths[teachingDate.getMonth()] + ' ' + teachingDate.getFullYear()
	const teachingTitle = document.createElement('h4');
				teachingTitle.classList.add('search-results_title');
				teachingTitle.innerHTML = element.title;
	const teachingBuyBtn = document.createElement('button');
	const teachingBuyBtnIcon = document.createElement('i');
				teachingBuyBtnIcon.setAttribute('class', 'fas fa-cart-plus');
	const teachingBuyBtnPrice = parseFloat(element.price).toFixed(2);
	const teachingBuyBtnPriceDiv = document.createElement('div');
				teachingBuyBtnPriceDiv.classList.add('buy-button_price');

				// SNIPCART BTN ATTRIBUTES
				console.log(element);
				teachingBuyBtn.setAttribute('data-item-id', element.id);
				teachingBuyBtn.setAttribute('data-item-price', teachingBuyBtnPrice);
				teachingBuyBtn.setAttribute('data-item-url', 'href', element.url);
				teachingBuyBtn.setAttribute('data-item-name', element.title);
				if ( element.hasOwnProperty('teachings_series')) {
					teachingBuyBtn.setAttribute('data-item-image', element.teachings_series[0].series_image);
				}
				
				
				teachingLink.appendChild(teachingImage)
				teachingLink.appendChild(teachingIcon)
				teaching.appendChild(teachingLink)
				teaching.appendChild(teachingTitle)
				teaching.appendChild(teachingDateHolder)
				if ( element.hasOwnProperty('price') ) {
					teachingBuyBtnPriceDiv.appendChild(teachingBuyBtnIcon);	
					teachingBuyBtnPriceDiv.innerHTML += teachingBuyBtnPrice;
					teachingBuyBtn.setAttribute('class', 'buy-button snipcart-add-item');
					teachingBuyBtn.appendChild(teachingBuyBtnPriceDiv);
					teaching.appendChild(teachingBuyBtn);
				}

	return teaching
}

// KEYUP SEARCH TIMER
let typeTimer;
const typeInterval = 250;
if ( searchbar ) {
	searchbar.addEventListener('keyup', (e) => {
		e.preventDefault();
		hideTermsList(appendableSearchTerms);
		clearTimeout(typeTimer)
		if ( searchbarInput.value ) {
			typeTimer = setTimeout(filterInput, typeInterval)
			clearBtn.style.display = 'block';
		} else {
			updateSearchMessage('', 'none')
			clearResults();
			clearBtn.style.display = 'none';
		}
	});
	
	// PREVENT ENTER KEYPRESS
	searchbar.addEventListener('keypress', function (e) {
		if (e.key === 'Enter') {
			e.preventDefault()
		}
	});
}

// SEARCH RESULTS MESSAGE
const updateSearchMessage = (message, display) => {
	if (searchResultsMessage) {
		searchResultsMessage.innerHTML = message;
		searchResultsMessage.style.display = display;
	}
}

// CLEAR RESULTS CONTAINER
const clearResults = () => {
	teachingsAll.innerHTML = '';
}

// CLEAR BUTTON
if (clearBtn) {
	clearBtn.addEventListener('click', () => {
		clearResults();
		updateSearchMessage('', 'none');
		searchbarInput.value = '';
		clearBtn.style.display = 'none';
	})
}

// FILTER BUTTONS
if (filterBtns) {
	filterBtns.forEach(btn => {
		btn.addEventListener('click', (e) => {
			e.preventDefault();
			const filterAction = btn.getAttribute('data-filter-action');
			const filterActionLower = btn.getAttribute('data-filter-action').toLowerCase();
			clearBtn.style.display = 'block';
			if ( filterActionLower == 'all') {
				filterInput('all');
				updateSearchMessage('All Teachings ', 'block')
				searchbarInput.value = '';
			} else if ( filterActionLower == 'products' ) {
				filterInput('products');
				updateSearchMessage('All Products', 'block')
				searchbarInput.value = '';
			} else {
				searchbarInput.value = filterActionLower;
				filterInput('series');
				updateSearchMessage(`${filterAction}`, 'block')
				searchbarInput.value = '';
			}
		})
	});
}

// ONLOAD PROVISIONS
window.onload = () => {
	updateSearchMessage('', 'none');
}