const eventCalendarMonth = document.getElementById('event-calendar_month');
const d = new Date();
let dayMonth = d.getDate();
let dayWeek = d.getDay();
let month = d.getMonth();
let year = d.getFullYear();
const prevMthBtn = document.getElementById('prev-month');
const nextMthBtn = document.getElementById('next-month');
const monthBtns = [...document.getElementsByClassName('month-button')];
const gridNum = 42;
const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
];
const dayNames = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
];
const dayNamesAbbreviated = [
    "Sun",
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat"
];

let currentMth = months[month];
let collectionDataSource = [];
let collectionDataSourceWithRecurring = [];
const viewBtns = [...document.getElementsByClassName('cal-view-btn')];
const views = [...document.getElementsByClassName('event-calendar_view')];
const monthController = document.getElementById('event-calendar_month-controller');
const weekController = document.getElementById('event-calendar_week-controller');
let currentView = 'month';
const monthViewBody = document.getElementById('event-calendar_month-days');
const monthViewHeading = document.getElementById('event-calendar_month-heading');
let weekViewBody = document.getElementById('event-calendar_week');
let weekDayActive = dayMonth;
const tabletSize = window.matchMedia('(max-width: 767px)');

// GET EVENT DATA
const getEventData = async (...args) => {
    const collectionPath = '/!/Fetch/collection/';
    const collection = args.map(arg => {
        const fullArg = collectionPath + arg;
        return axios.get(fullArg);
    });

    return new Promise((res) => {
        axios
            .all([...collection])
            .then(axios.spread((...responses) => {
                responses.forEach(response => {
                    collectionDataSource.push(response.data.data);
                })
                res(initializeCalendar());
            }));
    });
};


// const getEventData = async (...args) => {
//     const collectionPath = '/!/Fetch/collection/';
//     const collection = args.map(arg => {
//         const fullArg = collectionPath + arg;
//         return fetch(fullArg)
//             .then(response => response.json())
//             .then(data => {
//                 collectionDataSource.push(data.data);
//             });
//     });
//     return new Promise((res) => {
//         Promise.all([...collection]).then(() => {
//             res(initializeCalendar());
//         });
//     });
// }


// BUILD CALENDAR
const buildCalendar = () => {

    // Clear Calendar
    if (monthViewBody) {
        monthViewBody.innerHTML = '';
    }

    if (currentView == 'month') {
        let firstDay = (new Date(year, month)).getDay();
        const daysInMonth = 32 - new Date(year, month, 32).getDate();
        const gridNum = daysInMonth + firstDay <= 35 ? 35 : 42;

        for (let i = 0; i < gridNum; i++) {
            let day = document.createElement('div');
            day.classList.add('event-calendar_month-day');
            if (monthViewBody) {
                monthViewBody.appendChild(day);
            }
        }
    }
}

// BREAK UP DATE
const convertTwelveHour = (time) => {
    if (time) {
        let timeFull = time.slice(-5);
        let timeHours = timeFull.slice(0, 2);
        if (timeHours.charAt(0) == 0) {
            timeHours = timeHours.slice(1)
        }
        const timeMinutes = timeFull.slice(-2);
        const dayTime = timeHours >= 12 ? 'P' : 'A';
        let actualHour = timeHours % 12 != 0 ? timeHours % 12 : 12;
        const newTimeObject = {
            hours: actualHour,
            minutes: timeMinutes,
            dayTime: dayTime
        }
        return newTimeObject
    }
};

// UPDATE WEEK VIEW
const updateWeekView = () => {
    const thisMonth = d.getMonth();
    const weekViewNavWrap = document.createElement('div');
    weekViewNavWrap.id = 'event-calendar_week_nav-wrap';
    const weekViewNav = document.createElement('ul');
    weekViewNav.id = 'event-calendar_week_nav';
    const weekViewEvents = document.createElement('ul');
    weekViewEvents.id = 'event-calendar_week_events';
    const localDataSourceArr = [];
    const localDataSource = localDataSourceArr.concat(...collectionDataSource);
    const daysInMonth = 32 - new Date(year, month, 32).getDate();
    let prevMonth = (month - 1) < 0 ? 12 : month - 1;
    const daysInPrevMonth = 32 - new Date(year, prevMonth, 32).getDate();
    let weekStartDate = dayMonth - dayWeek;
    const offsetStartDate = daysInPrevMonth + weekStartDate;
    let weekDatesArr = [];

    for (let i = 0, j = weekStartDate, f = 0; i <= 6; i++) {
        if (j <= 0) {
            weekDatesArr.push(offsetStartDate + i)
            j++
        } else if (j >= daysInMonth) {
            weekDatesArr.push(f)
            f++
        } else {
            weekDatesArr.push(j)
            j++
        }
    }

    console.log({ weekDatesArr })

    const hasStartDate = localDataSource.filter(item => Object.prototype.hasOwnProperty.call(item, 'start_date')); 
    const weekViewNavHeader = document.createElement('div');
    weekViewNavHeader.id = 'event-calendar_week-dates';
    weekViewNavHeader.innerHTML = (thisMonth + (weekStartDate <= 0 ? 0 : 1)) + '/' + weekDatesArr[0] + ' - ' + (thisMonth + 1) + '/' + weekDatesArr[6];

    const clearWeekView = () => {
        if (weekViewBody) {
            weekViewBody.innerHTML = ''
        }
    }

    const clearWeekEvents = () => {
        weekViewEvents.innerHTML = '';
    }

    clearWeekView();

    const getDayEvents = () => {
        clearWeekEvents();
        let id;
        if (weekDayActive) {
            id = weekDayActive;
        }
        const eventsInDay = hasStartDate.filter(event => {
            if (event.start_date.slice(0, 4) == year && months[parseInt(event.start_date.slice(5, 7)) - 1] == currentMth) {
                return event.start_date.slice(8, 10) == id;
            }
        })
        if (eventsInDay.length > 0) {
            eventsInDay.forEach(event => {
                const eventId = event.id;
                const eventItem = document.createElement('li');
                const eventItemImage = document.createElement('img');
                axios
                    .get('/!/Fetch/entry/' + `${eventId}`)
                    .then((res) => {
                        eventItemImage.src = res.data.data.featured_image;
                    });
                const eventItemTitle = document.createElement('h3');
                eventItemTitle.innerHTML = event.title;
                const eventItemTimeStart = convertTwelveHour(event.start_date);
                const eventItemTimeEnd = convertTwelveHour(event.end_date);
                const eventItemTime = document.createElement('p');
                eventItemTime.innerHTML = `
                ${eventItemTimeStart.hours}` + (eventItemTimeStart.minutes != '00' ? `:${eventItemTimeStart.minutes}` : '') + `${eventItemTimeStart.dayTime} - 
                ${eventItemTimeEnd.hours}` + (eventItemTimeEnd.minutes != '00' ? `:${eventItemTimeEnd.minutes}` : '') + `${eventItemTimeEnd.dayTime}`
                const eventItemBtn = document.createElement('button');
                eventItemBtn.innerHTML = 'Learn More'
                eventItemBtn.classList.add('btn', 'modal_toggle');
                eventItemBtn.setAttribute('data-entry-id', event.id);
                eventItemBtn.setAttribute('start-date', event.start_date.slice(0, 10))

                eventItem.classList.add('event-calendar_week_event');
                eventItem.appendChild(eventItemImage);
                eventItem.appendChild(eventItemTitle);
                eventItem.appendChild(eventItemTime);
                eventItem.appendChild(eventItemBtn);
                weekViewEvents.appendChild(eventItem);
            });
        } else {
            weekViewEvents.innerHTML = '<div id="event-calendar_week_default">No events on this date.</div>'
        }
    };

    const changeDay = (e) => {
        const target = e.target;
        weekViewNav.querySelector('.active').classList.remove('active');
        target.classList.add('active');
        weekDayActive = target.id;
        getDayEvents();
    }

    // Week Nav
    weekDatesArr.forEach(date => {
        const navItem = document.createElement('li');
        navItem.classList.add('event-calendar_week_nav-item');
        navItem.id = date;
        if (date == weekDayActive) {
            navItem.classList.add('active');
        }
        navItem.addEventListener('click', (e) => {
            changeDay(e);
            getModalToggles();
        })
        tabletSize.matches == true ? navItem.innerHTML = dayNamesAbbreviated[weekDatesArr.indexOf(date)] : navItem.innerHTML = dayNames[weekDatesArr.indexOf(date)];
        weekViewNav.appendChild(navItem);
    });

    weekViewNavWrap.appendChild(weekViewNavHeader)
    weekViewNavWrap.appendChild(weekViewNav)

    if (weekViewBody) {
        weekViewBody.appendChild(weekViewNavWrap);
        weekViewBody.appendChild(weekViewEvents);
    }

    getDayEvents();

}

// UPDATE MONTH VIEW
const updateMonthView = async () => {
    const localDataSourceArr = [];
    const localDataSource = localDataSourceArr.concat(...collectionDataSource);

    // Sort Data
    const sortedDataSource = localDataSource.sort(function(dateA, dateB) {
        const a = new Date(dateA.start_date).getHours()
        const b = new Date(dateB.start_date).getHours()
        if (a > b) {
            return 1
        }
        if (a < b) {
            return -1
        }
    });

    const hasStartDate = sortedDataSource.filter(item => (Object.prototype.hasOwnProperty.call(item, 'start_date')));
    let firstDay = (new Date(year, month)).getDay();
    const daysInMonth = 32 - new Date(year, month, 32).getDate();
    const days = [...document.getElementsByClassName('event-calendar_month-day')];
    const gridNum = daysInMonth + firstDay <= 35 ? 35 : 42;
    let i, j, n;
    let prevMonth = (month - 1) < 0 ? 12 : month - 1;
    const daysInPrevMonth = 32 - new Date(year, prevMonth, 32).getDate();
    let daysFromPrevMonth = daysInPrevMonth - firstDay;
    let nextMonth = (month + 1) > 11 ? 1 : month + 1;

    // Set month name
    if (monthViewHeading) {
        monthViewHeading.innerHTML = months[month] + " " + year;
    }

    // INJECT EVENTS INTO DAY
    const injectEvents = (day) => {

        console.log({ day })

        // Create calendar day event list
        const eventList = document.createElement('ul');
        eventList.classList.add('event-calendar_event-list');

        // Filter events in single day
        const initialEventsToInject = hasStartDate.filter(event => {
            const eventStartDate = event.start_date.slice(0, 10)
            return eventStartDate == day.getAttribute('date')
        });

        // Get the recurring events
        const recurringEvents = hasStartDate.filter(event => event.recurring === true);
        const dayDate = new Date(day.getAttribute('date'));
        const recurringEventsInRange = recurringEvents.filter(event => {
            const eventStartDate = new Date(event.start_date);
            const eventEndDate = new Date(event.end_date);
            if (dayDate > eventStartDate && dayDate < eventEndDate) {
                return event


            } else {
                // console.log(recurringEvents)
            }
        });
        console.log('In range:', recurringEventsInRange)
        // console.log(recurringEvents)
        console.log(dayDate)
       


        const weeklyEvents = recurringEventsInRange.filter(event => event.frequency === 'weekly');
        const monthlyEvents = recurringEventsInRange.filter(event => event.frequency === 'monthly');

        const weekDayNum = day.getAttribute('data-week-day-num');
        const dayOfWeek = dayNames[weekDayNum].toLowerCase();
        const dayofMonth = dayDate.getDate();

        console.log("weekDayNum", weekDayNum);
        console.log('day of week', dayOfWeek);

        const alreadyAddedEvents = new Set();
        const recurringEventsOnDay = recurringEventsInRange.filter(event => {
            if (Object.prototype.hasOwnProperty.call(event, 'event_days')) { 

                let eventDays
                let eventDayMatch
                eventDays = [...event.event_days];
                eventDayMatch = eventDays.find(eventDay => eventDay === dayOfWeek)
                // console.log('match: ', eventDayMatch)

                if (eventDayMatch != undefined) {
                    if (event.frequency === 'monthly' && event.event_intervals.length > 0) {
                        const INTERVAL_MAPPER = {
                            "first": 7,
                            "second": 14,
                            "third": 21,
                            "fourth": 28
                        };

                        for (let x of event.event_intervals) {
                            let d = dayofMonth - INTERVAL_MAPPER[x];

                            if (d <= 0 && d > -7) {
                                if (!alreadyAddedEvents.has(event.id)) {
                                  alreadyAddedEvents.add(event.id);
                                  return event;
                                }
                            }
                        }
                    } else {
                        if (!alreadyAddedEvents.has(event.id)) {
                            alreadyAddedEvents.add(event.id);
                            return event;
                        }
                    }
                }
            }
        });





        console.log("Event on day: ", recurringEventsOnDay)
        console.log(recurringEventsOnDay)

        const eventsToInject = initialEventsToInject.concat(recurringEventsOnDay);

        if (eventsToInject.length > 0) {
            day.classList.add('day-has-events');
        }

        // Create elements for events in day
        eventsToInject.forEach(event => {
            const eventListItemTitle = document.createElement('div');
            eventListItemTitle.classList.add('event-calendar_event-list-title');
            eventListItemTitle.innerHTML = event.title;
            const eventListTime = document.createElement('div');
            eventListTime.classList.add('event-calendar_event-list-time');
            const eventListItem = document.createElement('li');


            // change day color for grand rapids
            if (event.location === "expression_location/grandrapids") {
                console.log(event.location);
                eventListItem.classList.add('event-calendar_event-list-item-b');

            } else {
                eventListItem.classList.add('event-calendar_event-list-item');
            }
            eventListItem.classList.add('modal_toggle');
            eventListItem.setAttribute('data-entry-id', event.id);



            // location toggle
            const locSelect = document.getElementById('location-select');
            const changeLoc = () => {
                          // correct selection being made but needs to happen at selection change
            const locSelect = document.getElementById('location-select');
            // locSelect.addEventListener('change', (e) => {
            
            // })

            const locSelected = locSelect.options[locSelect.selectedIndex].value;
            if (event.location !== "expression_location/grandrapids" && locSelected === "Grand Rapids" && eventListItem.classList.contains('event-calendar_event-list-item')) {
                eventListItem.classList.add('hidecal');
                console.log("not working right");

            } else if (locSelected === "Grandville" && eventListItem.classList.contains('event-calendar_event-list-item-b')) {
              eventListItem.classList.add('hidecal');
              console.log(locSelected);
              console.log("seriously");

            } else {
              eventListItem.classList.remove('hidecal');
              console.log(locSelected);
            }
            console.log(locSelected);
            }
            locSelect.addEventListener('change', changeLoc);

            // changeLoc(console.log("what the fuck"));








            // Set start/end dates for events
            if (event.start_date) {
                const timeObject = convertTwelveHour(event.start_date);
                eventListItem.setAttribute('start-date', event.start_date.slice(0, 10));
                eventListItem.setAttribute('start-time', `${timeObject.hours}:${timeObject.minutes}${timeObject.dayTime}`);
            }
            if (event.end_date) {
                const timeObject = convertTwelveHour(event.start_date);
                eventListItem.setAttribute('end-date', event.end_date.slice(0, 10));
                eventListItem.setAttribute('end-time', `${timeObject.hours}:${timeObject.minutes}${timeObject.dayTime}`);
            }

            eventListItem.appendChild(eventListItemTitle);
            eventListItem.appendChild(eventListTime);

            if (event.start_date && event.end_date) {
                const startTime = convertTwelveHour(event.start_date);
                const endTime = convertTwelveHour(event.end_date);
                if (startTime.minutes != '00' || endTime.minutes != '00') {
                    eventListTime.innerHTML = startTime.minutes != '00' ?
                        `${startTime.hours}:${startTime.minutes}${startTime.dayTime} - ${endTime.hours}:${endTime.minutes}${endTime.dayTime}` :
                        `${startTime.hours}${startTime.dayTime} - ${endTime.hours}${endTime.dayTime}`;
                } else if (startTime.minutes == '00' || endTime.minutes == '00') {
                    eventListTime.innerHTML = `${startTime.hours}${startTime.dayTime} - ${endTime.hours}${endTime.dayTime}`;
                }
            }
            eventList.appendChild(eventListItem);
        });
        day.appendChild(eventList)
    }


    // INJECT DAYS INTO CALENDAR OBJECT
    for (i = 0, j = 1, n = 1, weekDayNumCounter = 0; i <= gridNum; i++) {

        // Create date for day
        const dayNum = document.createElement('div');
        dayNum.classList.add('event-calendar_date');


        if (days[i]) {

            days[i].appendChild(dayNum);

            // Assign weekday number to be used for adding recurring events
            days[i].setAttribute('data-week-day-num', weekDayNumCounter);
            weekDayNumCounter < 6 ? weekDayNumCounter++ : weekDayNumCounter = 0;

            // Inject elements from previous month
            if (i < firstDay) {
                dayNum.innerHTML = daysFromPrevMonth + 1;
                days[i].classList.add('extra-day');
                if ((prevMonth + 1) > 12) {
                    days[i].setAttribute('date', (parseInt(`${year}`) - 1) + '-' + prevMonth + '-' + (daysFromPrevMonth + 1));
                } else {
                    days[i].setAttribute('date', `${year}-` + ((prevMonth + 1) > 10 ? prevMonth + 1 : '0' + (prevMonth + 1)) + '-' + (daysFromPrevMonth + 1));
                }
                injectEvents(days[i]);
                daysFromPrevMonth++;

                // Inject elements for selected month
            } else if (i >= firstDay && j <= (daysInMonth)) {
                dayNum.innerHTML = j;
                days[i].setAttribute('date', `${year}-` + ((month + 1) < 10 ? '0' + (month + 1) : month + 1) + '-' + (j >= 10 ? j : '0' + j));
                injectEvents(days[i]);
                j++

                // Inject elements for next month
            } else if (j > daysInMonth && i < gridNum) {
                dayNum.innerHTML = n;
                days[i].classList.add('extra-day');
                if (nextMonth == 1) {
                    days[i].setAttribute('date', (parseInt(`${year}`) + 1) + '-' + (nextMonth < 10 ? '0' + nextMonth : nextMonth) + '-' + (n > 10 ? n : '0' + n));
                } else {
                    days[i].setAttribute('date', `${year}-` + ((nextMonth + 1) < 10 ? '0' + (nextMonth + 1) : nextMonth + 1) + '-' + (n > 10 ? n : '0' + n));
                }
                injectEvents(days[i]);
                n++;
            }
        }
    }
};

// Remove overflow content from calendar squares and create list button
const removeOverflow = (callback) => {
    let overflowNum = 0;
    const days = [...document.getElementsByClassName('event-calendar_month-day')];

    const getHeights = (day) => {
        const dayHeight = day.offsetHeight
        const contentHeight = day.scrollHeight
        return contentHeight > dayHeight
    }

    const deleteEvent = (day) => {
        overflowNum++;
        const eventList = day.querySelector('.event-calendar_event-list');
        const events = [...eventList.children];
        const newEvents = events.slice(0, -1);
        eventList.innerHTML = ''
        newEvents.forEach(event => {
            day.querySelector('.event-calendar_event-list').appendChild(event)
        })
        if (getHeights(day) == true) {
            deleteEvent(day)
        } else {
            day.querySelector('.event-calendar_month-day_more').innerHTML += ` (${overflowNum})`;
            overflowNum = 0;
        }
    }

    const adjustDays = async () => {
        for (const day of days) {
            const moreBtn = document.createElement('button');
            moreBtn.classList.add('modal_toggle');
            moreBtn.classList.add('event-calendar_month-day_more');
            moreBtn.setAttribute('type', 'button');
            moreBtn.innerHTML = tabletSize.matches == true ? 'View' : 'More';
            const hasOverflow = getHeights(day);
            if (hasOverflow) {
                day.appendChild(moreBtn);
                deleteEvent(day);
            }
        }
    }

    adjustDays();
    getModalToggles();
};

const enableButtons = () => {
    const viewBtns = [...document.getElementsByClassName('cal-view-btn')];
    viewBtns.forEach(button => {
        button.disabled = false;
    })
};

// INITIALIZE CALENDAR
const initializeCalendar = () => {
    buildCalendar();
    updateMonthView();
    updateWeekView();
    removeOverflow();
    setTimeout(() => {
        enableButtons();
    }, 100);
}




// TOGGLE CALENDAR VIEW
const toggleView = (clicked) => {

    viewBtns.forEach(btn => {
        btn.classList.remove('active');
        if (btn.dataset.toggle == clicked) {
            btn.classList.add('active');
            currentView = btn.dataset.toggle;
        }
    });

    views.forEach(view => {
        view.classList.remove('visible');
        if (view.dataset.view == clicked) {
            view.classList.add('visible');
        }
    });

    if (clicked != 'month') {
        monthController.classList.remove('visible')
        weekController.classList.add('visible');
    } else {
        weekController.classList.remove('visible');
        monthController.classList.add('visible');
    }
}

monthBtns.forEach(btn => {
    btn.addEventListener('click', (e) => {
        const target = e.target;
        const prevMonth = month - 1;
        const nextMonth = month + 1;

        if (target == prevMthBtn) {
            if (month > 0) {
                month = prevMonth;
            } else {
                month = 11;
                year--;
            }
        } else {
            if (month < 11) {
                month = nextMonth
            } else {
                month = 0;
                year++
            }
        }

        initializeCalendar();
    });
});

viewBtns.forEach(btn => {
    btn.addEventListener('click', (e) => {
        const clicked = e.target.dataset.toggle;
        toggleView(clicked)
    })
});

if (window.location.pathname === '/events') {
    buildCalendar();
    getEventData('calendar');
}

// REINITIALIZE CALENDAR ON RESIZE
var resized;
window.onresize = function() {
    clearTimeout(resized);
    resized = setTimeout(doneResizing, 500);
}

function doneResizing() {
    if (currentView == 'month') {
        initializeCalendar()
    }
}

