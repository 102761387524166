const modalWrap = document.getElementById('modal_wrap');
const modalClose = document.getElementById('modal_close');
let clickedDate = '';
const monthArr = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

const getModalToggles = () => {
  const modalToggles = [...document.getElementsByClassName('modal_toggle')];
  setModalToggles(modalToggles)
};

const setModalToggles = (modalToggles) => {
  modalToggles.forEach(toggle => {
    toggle.addEventListener('click', (e) => {
      if (toggle.classList.contains('event-calendar_month-day_more')) {
        const date = e.target.closest('.event-calendar_month-day').getAttribute('date')
        getEntries(date);
      } else {
        const entry = e.target.closest('[data-entry-id]');
        if ( e.target.closest('.event-calendar_month-day')) {
          clickedDate = e.target.closest('.event-calendar_month-day').getAttribute('date')
        }
        if ( entry ) {
          getEntryData(entry)
        } else {
          showModal()
        }
      }
    });
  });
};

const getEntryData = (entry) => {
  const entryId = entry.getAttribute('data-entry-id');
  axios
    .get(`/!/Fetch/entry/${entryId}`)
    .then(function(response) {
      const entryData = response.data.data;
      showModal(entryData)
  });
};

// Get entries when multiple calendar events
const getEntries = (date) => {
    const localDataSourceArr = [];
    const localDataSource = localDataSourceArr.concat(...collectionDataSource);

    // create a Set to store the unique start_date strings
    let uniqueStartDates = new Set();

    // filter out duplicate events by comparing the start_date property
    // if an event's start_date has already been added to the set, it is a duplicate, and we don't add it to the filteredData array
    let filteredData = localDataSource.filter(event => {
        if (event.hasOwnProperty('start_date')) {
            if (event.hasOwnProperty('start_date') && event.start_date.slice(0, 10) === date) {
                return event;
            } else if (event.recurring) {
                const testStartDate = new Date(event.start_date);
                const testEndDate = new Date(event.end_date);
                const testDate = new Date(date);
                const dateDay = dayNames[testDate.getUTCDay()].toLowerCase();
                if (testStartDate.getTime() <= testDate.getTime() && testEndDate.getTime() >= testDate.getTime()) {
                    if (event.hasOwnProperty('event_days') && event.event_days.includes(dateDay)) {
                        return event;
                    }
                }
            }
        }
    });
    const sortedDataSource = filteredData.sort((dateA, dateB) => {
        const a = new Date(dateA.start_date).getHours()
        const b = new Date(dateB.start_date).getHours()
        if (a > b) {
            return 1
        }
        if (a < b) {
            return -1
        }
    });
    console.log({ sortedDataSource });
    showModal(sortedDataSource);
};
// Inject modal data
const injectModalData = (data) => {
  if (document.getElementById('modal-list')) {

    document.getElementById('modal-list').remove();
  }
  console.log({data})

  const modal = document.getElementById('modal');
  const modalElements = [...modal.children]
  const modalType = modal.getAttribute('data-modal-type');

  if (data.length) {

    modalElements.forEach(elem => {
      if ( ! elem.classList.contains('modal_close') ) {
        elem.style.display = 'none';
      }
    })
    
    const modalList = document.createElement('ul');
    modalList.setAttribute('id', 'modal-list');

    data.forEach(elem => {
      const modalListItem = document.createElement('li');
            modalListItem.setAttribute('data-entry-id', elem.id);
            modalListItem.addEventListener('click', () => {
              modalList.remove();
              getEntryData(modalListItem);
            });
      const itemContent = document.createElement('div');
            itemContent.classList.add('modal-list-content');
      const itemHeader = document.createElement('h3');
            itemHeader.innerHTML = elem.title;
      const max = 250;
      const itemCopy = document.createElement('p');
      if ( elem.preview ) {
        const count = elem.preview.length;
        itemCopy.innerHTML = elem.preview.substring(0, max) + ( count > max ? '...' : '' );
      }
      const startTime = convertTwelveHour(elem.start_date);
      const itemTime = document.createElement('div');

        if (elem.location === "expression_location/grandrapids") {
            itemTime.classList.add('modal-list-time-mhgr');
        } else {
            itemTime.classList.add('modal-list-time');
        }
            
      itemTime.innerHTML = "<p>Time</p>" + startTime.hours + ':' + startTime.minutes + startTime.dayTime;

      itemContent.appendChild(itemHeader);
      itemContent.appendChild(itemCopy);
      modalListItem.appendChild(itemContent);
      modalListItem.appendChild(itemTime);
      modalList.appendChild(modalListItem);
    });

    modal.appendChild(modalList);
    console.log(modalList)

  } else {
    modalElements.forEach(elem => {
      elem.style.display = 'block';
    })
    switch (modalType) {
  
      // STAFF
      case 'staff': {
                const name = modal.querySelector('#staff-modal_name')
        const title = modal.querySelector('#staff-modal_title')
        const bio = modal.querySelector('#staff-modal_bio')
        const mail = modal.querySelector('#staff-modal_mail')
        name.innerHTML = data.first_name + ' ' + data.last_name;
        title.innerHTML = data.title;
        if ( typeof data.biography === 'string') {
          bio.innerHTML = data.biography;
          console.log(data.preview === true);
          console.log(typeof data.biography);
        } else {
          bio.innerHTML = "";
        }
        mail.innerHTML = '<i class="fas fa-envelope"></i>' + data.email;
        mail.setAttribute('href', 'mailto:' + data.email);
        break;
      }

  
      // VOLUNTEER
      case 'volunteer': {
                const header = modal.querySelector('#volunteer-modal_header');
        const subHeader = modal.querySelector('#modal_sub-header');
        const link = modal.querySelector('#volunteer-modal_link');
        header.innerHTML = data.title;
        if ( data.preview != null ) {
          subHeader.innerHTML = data.preview;
        }
        link.innerHTML = 'Learn More';
        link.setAttribute('href', data.url).classList.add('btn action-1');
        break;
      }

  
      // EVENTS
      case 'events': {
                const eventDate = modal.querySelector('#modal_date');
              if (data.recurring === true) {
                const thisDate = new Date(clickedDate)
                eventDate.innerHTML = months[thisDate.getUTCMonth()] + ' ' + thisDate.getUTCDate() + ' - ' + convertTwelveHour(data.start_date).hours + ':' + convertTwelveHour(data.start_date).minutes + convertTwelveHour(data.start_date).dayTime;
                console.log(thisDate)
                console.log(eventDate)
              } else {
                eventDate.innerHTML = months[parseInt(data.start_date.slice(5,7)) - 1] + ' ' + data.start_date.slice(8,10) + ' - ' + convertTwelveHour(data.start_date).hours + ':' + convertTwelveHour(data.start_date).minutes + convertTwelveHour(data.start_date).dayTime;
                console.log(eventDate)
              }
        const eventHeader = modal.querySelector('#modal_header');
              eventHeader.innerHTML = data.title;
        const eventSubHeader = modal.querySelector('#modal_sub-header');
        const eventLink = modal.querySelector('#modal_link');
              eventSubHeader.innerHTML = data.preview;
              eventLink.innerHTML = 'Learn More';
              eventLink.setAttribute('href', data.url);
              eventLink.classList.add('btn');

               if (data.location === "expression_location/grandrapids") {
                eventLink.classList.add('action-mhgr');
                eventLink.classList.remove('action-1');
              } else {
              eventLink.classList.add('action-1');
              eventLink.classList.remove('action-mhgr');
              }
        break;
      }


      // CAREERS
      case 'careers': {
                const jobHeader = modal.querySelector('#job-modal_title');
              jobHeader.innerHTML = data.title;
              const content = modal.querySelector('#job-modal_content');
              if ( data.preview != null ) {
                content.innerHTML = data.preview;
              }
        const jobLink = modal.querySelector('#job-link');
              jobLink.innerHTML = 'Download Job Description';
              jobLink.setAttribute('href', data.job_details[0]);
        const applyLink = modal.querySelector('#apply-link');
              applyLink.innerHTML = 'Apply Now';
              applyLink.setAttribute('href', 'mailto:hr@marshill.org');
        break;
      }

  
      default:
        console.log('No modal');
    }
  }
}

const showModal = (data) => {
  modalWrap.classList.add('open');
  if (data) {
    injectModalData(data);
  }
}

const hideModal = () => {
  modalWrap.classList.remove('open');
  emptyModal();
}

const emptyModal = () => {
  const modalContent = [...document.getElementById('modal').children];
  const modalList = document.getElementById('modal-list')
  modalList.remove()
  modalContent.forEach(element => {
    if ( ! element.classList.contains('modal_close') && ! element.id == 'job-modal_buttons' ){
      element.innerHTML = '';
    }
  });
};

// Close modal button
if ( modalClose) {
  modalClose.addEventListener('mouseup', hideModal);
}

// Close modal if click away from modal
if ( modalWrap ) {
  modalWrap.onclick = (e) => {
    if (e.target.getAttribute('id') === 'modal_wrap') {
      hideModal();
    }
  }
}

// Close modal w/escape key
document.onkeyup = (e) => {
  if ( e.key === 'Escape') {
    hideModal();
  }
}

// Initiate toggles
if (window.location.pathname == '/staff' || window.location.pathname == '/volunteer' || window.location.pathname == '/events' ) {
  getModalToggles();
}