const seachTerms = '/!/Fetch/taxonomy/sermon_terms';
const searchTermsRequest = axios.get(seachTerms);
const searchBar = document.querySelector('.searchbar-content_input-wrap');
const searchBarFocus = document.getElementById('searchbar-input');
let appendableSearchTerms;
let listIsShown = false;

// Listen for input click
searchBarFocus.addEventListener('click', () => {
  showTermsList(appendableSearchTerms);
  setTimeout(() => {
    listIsShown = true
  }, 100);
});

// Listen for click elsewhere
document.addEventListener('click', () => {
  if ( listIsShown === true ) {
    hideTermsList(appendableSearchTerms)
    listIsShown = false;
  }
})

searchTermsRequest.then(res => {
  let searchTermsArr = [];
  const resData = res.data.data;
  searchTermsArr.push(resData);
  createTermsListElement(searchTermsArr);
})

const createTermsListElement = ([searchTermsArr]) => {
  const termsListElement = document.createElement('ul');
  termsListElement.setAttribute('id', 'search-list');
  
  searchTermsArr.forEach(term => {
    const listItem = createTermsListItem(term);
    termsListElement.appendChild(listItem);
  })
  
  appendableSearchTerms = termsListElement;
}

const createTermsListItem = (term) => {
  const termsListItem = document.createElement('li');
        termsListItem.classList.add('search-list_item');
        termsListItem.setAttribute('data-term-value', term.slug);
        termsListItem.innerHTML = term.title;
        termsListItem.addEventListener('click', () => {
          searchbarInput.value = term.slug;
          filterInput('searchlist')
        })
  return termsListItem
}

const showTermsList = (list) => {
  console.log(list)
  console.log('searchbar type: ', typeof searchBar)
  searchBar.appendChild(list)
  list.style.display = 'block'
}

const hideTermsList = (list) => {
  list.style.display = 'none'
}

const injectTerm = (searchTerm) => {

}