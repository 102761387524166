const vToggleArr = [...document.getElementsByClassName('volunteer_list-toggle')];
const volunteer = document.querySelector('.volunteer');

vToggleArr.forEach(item => {
  item.addEventListener('click', (e) => {
    volunteerCollapse();

    const target = e.target;
    const list = target.closest('li').querySelector('.volunteer_sub-category-list');
    const height = getHeight(list);
    const viewBtn = target.closest('.volunteer_category-list-header').querySelector('.volunteer_list-toggle');
    viewBtn.classList.add('active-view');
    viewBtn.innerHTML = 'Close';
    list.classList.add('open');
    list.style.height = height;

    setTimeout(() => {
      list.style.height = '';
    }, 350);
  });
});

function getHeight(list) {
  list.style.display = 'flex';
  const height = list.scrollHeight + 'px';
  list.style.display = '';
  return height;
};

function volunteerCollapse() {
  const openList = volunteer.querySelector('.open');
  if (openList) {
    const viewBtn = document.querySelector('.active-view');
    
    openList.style.height = openList.scrollHeight + 'px';
    
    setTimeout(() => {
      openList.style.height = '0';
    }, 1);
    
    setTimeout(() => {
      openList.classList.remove('open');
      viewBtn.classList.remove('active-view');
      viewBtn.innerHTML = 'View';
    }, 350)
  }
};