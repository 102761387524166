const showAudio = document.getElementById('showAudio');
const showVideo = document.getElementById('showVideo');
const audio = document.querySelector('.sermon-audio');
const video = document.querySelector('.sermon-video');
const audioButton = document.getElementById('audioButton');
const playFormat = sessionStorage.getItem('playFormat');
const latestTeachingBtns = [...document.getElementsByClassName('latest-teaching-button')];

if ( latestTeachingBtns.length ) {
  latestTeachingBtns.forEach(btn => {
    btn.addEventListener('click', () => {
      const id = btn.getAttribute('id');
      id == 'audioButton' ? sessionStorage.setItem('playFormat', 'audio') : sessionStorage.setItem('playFormat', 'video')
    })
  })
}

if ( video && playFormat != null ) {
  playFormat == 'audio' ? video.style.display = 'none' : audio.style.display = 'none';
  sessionStorage.removeItem('playFormat');
} else {
  if (audio) {
    audio.style.display = 'none';
  }
}

if (showAudio) {
  showAudio.addEventListener('click', () => {
    video.style.display = 'none';
    audio.style.display = 'block';
  });
};

if (showVideo) {
  showVideo.addEventListener('click', () => {
    audio.style.display = 'none';
    video.style.display = 'block';
  });
};